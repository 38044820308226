.ios-safe-area .map-container {
    padding-bottom: env(safe-area-inset-bottom);
  }

.map-page {
    height: 100vh;
    height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    display: flex;
    flex-direction: column;
}

.map-container {
    flex: 1;
    position: relative;
    overflow: hidden;
}

.leaflet-container {
    width: 100%;
    height: 100%;
}